 
var AjaxForms = {

    options : {
        target        : null,
        stop_response : false,
        empty_form 	  : true,
        successFn     : null,
        errorFn       : null,
        method        : 'POST',
        recaptcha: false,
        recaptcha_key: null
    },
    
    init:function(t){
        var $this = this;
         
         /*
        if($this.options.recaptcha ===  true){
            $.ajax({
                url: 'https://www.google.com/recaptcha/api.js',
                dataType: "script",
                success:function(){
                    try{
                        grecaptcha.remder();
                    }catch (Error){}
                }
            });
        }
        */
        $this.options.target = t;

        $($this.options.target).parsley({
			excluded: "textarea:hidden, input:hidden, select:hidden"
		});


         if ($this.options.recaptcha === true) {
            $($this.options.target).on('submit', function ($e) {
                $e.preventDefault();
                if (typeof (grecaptcha) !== 'undefined') {
                    grecaptcha.ready(function () {
                        grecaptcha.execute($this.options.recaptcha_key, { action: 'submit' }).then(function (token) {
                           
                            $($this.options.target).append('<input class="gr-recaptcha" name="g-recaptcha-response" type="hidden" value="'+token+'"/>');
                            
                            $this.submit_form();
                        });
                    });
                }
            }).addClass('ready');

        } else {
            $($this.options.target).on('submit', function ($e) {

              
                $this.submit_form();
                $e.preventDefault();

            }).addClass('ready');
        }

       
    },
    form_submit_response : function( msg , type){
        actionResponseModal(msg,type);
    },
    submit_form:function(){

        var $this       = this,
            $form       = $($this.options.target),
            successFn   = $this.options.successFn,
            errorFn     = $this.options.errorFn,
            url         = $form.attr('action'),
            postData    = $form.serialize(),
            method      = $form.attr('method'),
            $submitBTN  = $form.find('button[type="submit"]');

		if(!$submitBTN.hasClass('like-btn')){
			
			$submitBTN.prop('disabled',true);
			$submitBTN.data('initialText',$submitBTN.html());
			//$submitBTN.html('<i class="fa fa-spinner fa-spin"></i>');
		}
		
		
        $.ajax({
            url     : url,
            data    : postData,
            type    : method,
            success: function (data) {			
				
                if($this.options.recaptcha ===  true){
                    if($form.find('.gr-recaptcha').length>0 ){
                                $($this.options.target).find('.gr-recaptcha').remove();
                            }
                }
				
                $submitBTN.html($submitBTN.data('initialText'));
               
				if($this.options.empty_form){
					$form.find('input,textarea,select').not(':hidden').each(function(){
	                    $(this).val('');
	                    $(this).removeClass('parsley-success');
	                });	
				} 

                if(typeof(successFn) !== 'undefined'){
                    successFn(data);
                }                
                if(!$this.options.stop_response){
                	$this.form_submit_response( data.message || 'submitted', 'success');		
                }
            },
            error: function (response) {
                 if($this.options.recaptcha ===  true){
                    if($form.find('.gr-recaptcha').length>0 ){
                                $($this.options.target).find('.gr-recaptcha').remove();
                            }
                }
                $submitBTN.prop('disabled',false);	
                var responseJSON = jQuery.parseJSON(response.responseText);
                
                $submitBTN.html($submitBTN.data('initialText'));

                if(typeof(errorFn) !== 'undefined'){
                    errorFn( responseJSON );
                }else{
                    for(var i in responseJSON.errors){
                        if(i == 'g-recaptcha-response'){
                            return actionResponseModal('Are you a robot?','error');
                        }else{
                            var t = $form.find('[name="'+i+'"]').closest('.form-group');
                            
                            $form.find('[name="'+i+'"]').addClass('parsley-error');

                            var error_string = typeof( responseJSON.errors[i]) ==='string' ?  responseJSON.errors[i] : responseJSON.errors[i].join('')

                            if(t.find('.parsley-errors-list').length > 0){
                                t.find('.parsley-errors-list').html('<div class="parsley-type" style="color:red">'+error_string+'</div>');
                            }else{

                                t.append('<div class="parsley-errors-list filled text-left" style="color:red"><div class="parsley-type">'+error_string+'</div></div>');    
                            }

                        }
                    }

                    $this.form_submit_response( responseJSON.message || 'error', 'error');
                }
            }
        });
    } 
}
